
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CooperativeMotorInsuranceForm from "@/components/reusable/CooperativeMotorInsuranceForm.vue";
import { Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "private-motor-insurance-selection",
  components: {
    Form,
    CooperativeMotorInsuranceForm
  },
  props: {
    businessType: String,
    businessClass: String,
    publicId: String,
    coopPublicId: String
  },
  data() {
    return {
      userRole: ""
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const metadataValidator = Yup.object().shape({
      metadata: Yup.array()
        .of(
          Yup.object().shape({
            // vehicleOwner: Yup.string()
            //   .required()
            //   .label("Vehicle owner name"),
            vehicleValue: Yup.string().label("Vehicle value"),
            vehicleMake: Yup.mixed().required().label("Vehicle brand"),
            vehicleModel: Yup.string().required().label("Vehicle model"),
            vehicleRegistrationNumber: Yup.string()
              .required()
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string().required().min(1).label("Engine number"),
            bodyType: Yup.string().required().label("Body type"),
            vehicleColor: Yup.string().required().label("Vehicle colour"),
            vehicleLicense: Yup.mixed().required().label("Vehicle license")
          })
        )
        .strict()
    });

    const saveComprehensive = (values, { resetForm }) => {
      console.clear();

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["metadata"].length; x++) {
              for (const [key, value] of Object.entries(
                values["metadata"][x]
              )) {
                if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", values["businessClass"]);
            formData.append("umbrellaPolicyID", values["umbrellaPolicyID"]);
            formData.append("package", values["package"]);
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(variables.COOPERATIVE_CART_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
              .then((response) => {
                // store.commit(Mutations.CART_MUTATION, response.data.data.cart);
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "cooperative-admin-insurance-packages-cart"
                  // params: {
                  //   publicId: response.data.data.cartItemPublicId,
                  // },
                });
                // router.push({ name: "insurance-packages-cart" });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Private", [
        "Insurance Packages",
        "Motor Insurance",
        "Quote"
      ]);
    });

    return {
      submitButton1,
      saveComprehensive,
      metadataValidator
    };
  },
  created() {
    //Set page title
    document.title = "Private Motor Insurance | " + process.env.VUE_APP_TITLE;
  }
});
