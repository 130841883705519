
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";

export default defineComponent({
  name: "vehiclae-make-and-model",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    registrationName: String,
    registrationModel: String,
    chassisName: String,
    chassisModel: String,
  },
  methods: {
    removeSpecialCharacters(event) {
      if (variables.containsSpecialChars(event.target.value)) {
        const newValue = event.target.value
          .replace(/\D/g, "")
          .replace(`\`!@#$%^&*()_+\\=\\[\\]{};':"\\|,.<>\\/?~`, ",");

        setTimeout(() => {
          this.$nextTick(() => (event.target.value = newValue));
        }, 0.1);
      }
    },
  },
});
