
import { defineComponent } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import VehicleMakeAndModel from "@/components/reusable/VehicleMakeAndModel.vue";
import VehicleRegAndChassisNumber from "@/components/reusable/VehicleRegAndChassisNumber.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import VehicleColorChart from "@/components/modals/VehicleColorChart.vue";
import VehicleBodyTypesModal from "@/components/modals/VehicleBodyTypes.vue";
import VehicleColors from "@/views/reusable/buy-insurance/motor-insurance/vehicle-colours.json";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";
// import Members from "@/views/reusable/buy-insurance/motor-insurance/members.json";

export default defineComponent({
  name: "cooperative-motor-comprehensive-component",
  components: {
    Field,
    ErrorMessage,
    VehicleMakeAndModel,
    VehicleRegAndChassisNumber,
    ImagesUploader,
    VehicleColorChart,
    VehicleBodyTypesModal,
  },
  props: {
    publicId: String,
    businessType: String,
    businessClass: String,
    coopPublicId: String,
  },
  data() {
    return {
      rate: 0.0 as number,
      underwriters: [],
      metadata: [
        {
          vehicleOwner: "",
          vehicleValue: "",
          vehicleMake: "",
          vehicleModel: "",
          vehicleRegistrationNumber: "",
          chassisNumber: "",
          engineNumber: "",
          engineCapacity: "",
          yearManufactured: "",
          vehicleColor: "",
          bodyType: "",
          vehicleLicense: "",
          vehicleImages: "",
          member: "",
          vehiclePremium: 0.0 as number,
        },
      ],
      totalPremium: 0.0 as number,
      totalSumInsured: 0.0 as number,
      insurancePackage: "CMP",
      vehicleColors: VehicleColors,
      vehicleBodyTypes: VehicleBodyTypes,
      members: [],
      mutatedBusinessType: "PM",
      mutatedBusinessClass: "MOT",
      mutatedPublicId: this.publicId,
      bodyTypes: VehicleBodyTypes.private,
    };
  },
  created() {
    // Get the private motor rate and members list
    // this.getPrivateMotorRate();
    this.getMembers();
  },
  methods: {
    premium(event, position) {
      // Add comma and allow decimal point vehicle value field
      variables.numberFormatter(event.target.id);
      const newValue = event.target.value;

      // Remove comma from vehicle value
      const inputValue = variables.removeComma(event.target.value);

      // Call getPremium only when the length is greater than 5
      if (event.target.value.length > 6) {
        this.getPremium(
          this.publicId,
          this.publicId,
          inputValue,
          event,
          position
        );
      }

      return this.$nextTick(() => (event.target.value = newValue));
    },
    getPremium(underwriter, packageId, sumInsured, event, position) {
      const payload = {
        businessClass: "MOT",
        cooperative: this.coopPublicId,
        policy: this.publicId,
        sumInsured: sumInsured,
      } as object;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(variables.PREMIUM_ROUTE, payload)
          .then(({ data }) => {
            variables.premiumCalculator(
              event.target.ariaValueNow,
              data.data.grossPremium,
              event.target.ariaValueText
            );

            // Call vehicle premium calculator
            this.vehiclePremiumCalculator(
              event.target.ariaValueNow,
              data.data.grossPremium,
              position
            );

            // Append total premium value tot total premium field
            this.totalPremium = variables.totalPremiumValue();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }

      this.totalSumInsured = variables.totalSumInsured(
        sumInsured,
        event.target.title
      );
    },
    addDynamicField() {
      this.metadata.push({
        vehicleOwner: "",
        vehicleValue: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleRegistrationNumber: "",
        chassisNumber: "",
        engineNumber: "",
        engineCapacity: "",
        yearManufactured: "",
        vehicleColor: "",
        bodyType: "",
        vehicleLicense: "",
        vehicleImages: "",
        member: "",
        vehiclePremium: 0.0 as number,
      });

      variables.toastAlert("Row added", "success");
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      this.metadata.splice(counter, 1);
      variables.toastAlert("Row removed", "success");

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    getMembers() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.COOPERATIVE_MEMBERS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.members = data.data.sort(variables.dynamicSort("name"));
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPrivateMotorRate() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.COOPERATIVE_RATE_ROUTE}/${this.mutatedBusinessClass}/${this.mutatedBusinessType}/${this.insurancePackage}`
        )
          .then(({ data }) => {
            //Assign data to form fields
            this.rate = data.data.rate.value;
            this.members = data.data.members.sort(
              variables.dynamicSort("name")
            );
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    vehicleLicenseSelected(event, position) {
      this.metadata[position].vehicleLicense = event.target.files[0];
    },
    vehicleImagesSelected(event, position) {
      this.metadata[position].vehicleImages = event.target.files;
    },
    vehiclePremiumCalculator(rate: number, value: number, position: number) {
      this.metadata[position].vehiclePremium = +variables.vehiclePremium(
        rate,
        value
      );
    },
    appendMemberName(event, position) {
      const vehicleOwnerName = document.getElementById(
        "vehicle-owner-" + position
      ) as HTMLInputElement;

      const realVehicleOwnerName = document.getElementById(
        "real-vehicle-owner-" + position
      ) as HTMLInputElement;

      const value =
        event.target.options[event.target.selectedIndex].dataset.name;

      realVehicleOwnerName.value = value;
      vehicleOwnerName.value = value;
      this.metadata[position].vehicleOwner = value;
    },
    vehicleOwnerNameUpdate(event, position) {
      const realVehicleOwnerName = document.getElementById(
        "real-vehicle-owner-" + position
      ) as HTMLInputElement;

      const value = event.target.value;
      realVehicleOwnerName.value = value;
      this.metadata[position].vehicleOwner = value;
    },
  },
});
