
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Private from "@/views/cooperative-admin/insurance-packages/motor-insurance/Private.vue";
import UploadSchedule from "@/views/cooperative-admin/insurance-packages/motor-insurance/UploadSchedule.vue";
import { Field } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";

export default defineComponent({
  name: "cooperative-motor-insurance-quote",
  components: {
    Field,
    Private,
    UploadSchedule,
    TotalPremiumWidget
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      coopPublicId: this.$route.params.coopPublicId,
      businessType: this.$route.params.businessType,
      businessClass: this.$route.params.businessClass,
      displaySchedule: false,
      displayForm: false
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Quote", [
        "Cooperative",
        "Insurance Packages",
        "Motor Insurance"
      ]);
    });
  },
  created() {
    // If busines type policy is null, redirect to policy listing view
    if (this.publicId == "") {
      this.$router.push({ name: "cooperative-admin-policy-listing" });
    }
    //Set page title
    document.title =
      "Cooperative Motor Insurance Quote | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    uploadSchedule() {
      this.displaySchedule = true;
      this.displayForm = false;
    },
    motorInsuranceForm() {
      this.displayForm = true;
      this.displaySchedule = false;
    }
  }
});
