
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ExcelFileReader from "@/components/reusable/ExcelFileReader.vue";

export default defineComponent({
  name: "cooperative-motor-insurance-quote",
  props: {
    busienssType: String,
    businessClass: String
  },
  components: {
    ExcelFileReader
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Upload Schedule", [
        "Cooperative",
        "Insurance Packages",
        "Motor Insurance"
      ]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Cooperative Motor Insurance Upload Schedule | " +
      process.env.VUE_APP_TITLE;
  }
});
