
import { defineComponent, ref, watch } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import readXlsxFile from "read-excel-file";
import ResusableFunctions from "@/composables/ReusableFunctions";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "excel-file-reader-component",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    preview: Boolean,
    businessClass: String,
    redirect: String,
  },
  setup(props) {
    const router = useRouter();
    const { capitalize, displayDatatable } = ResusableFunctions();
    const submitButton1 = ref<HTMLElement | null>(null);
    const schedule = ref("");
    const rows = ref([{}]);
    const payload = ref({
      businessClass: props.businessClass,
      policies: [],
    });

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        // dateFormat: "yyyy-mm-dd",
        readXlsxFile(file, { trim: false }).then((data) => {
          rows.value = data;
          payload.value.policies = data as any;
        });
      }
    };

    const createLegacyInsurance = () => {
      console.clear();

      if (rows.value?.length < 2) {
        variables.toastAlert(
          "It appears you are yet to select a schedule",
          "error"
        );
      }

      // if (submitButton1.value) {
      //   // Activate indicator
      //   submitButton1.value.setAttribute("data-kt-indicator", "on");

      //   setTimeout(() => {
      //     submitButton1.value?.removeAttribute("data-kt-indicator");

      // Send form data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(variables.LEGACY_POLICY_UPLOAD, payload.value)
          .then((response) => {
            variables.toastAlert(response.data.data.message, "success");
            router.push({
              name: props.redirect,
            });
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
      //   }, 2000);
      // }
    };

    // Watch for changes in rows
    watch(rows, (newRows, oldRows) => {
      displayDatatable(["basicExample"]);
    });

    return {
      schedule,
      rows,
      handleFileChange,
      capitalize,
      createLegacyInsurance,
    };
  },
});
